import React, { FC, useEffect } from 'react';

import './ScrollingText.css';

interface ScrollingTextProps {
  text: string;
  endOffset?: number;
}

export const ScrollingText: FC<ScrollingTextProps> = ({
  text,
  endOffset = 60,
}) => {
  const animationName = `text-scroll-animation-${endOffset}`;

  useEffect(() => {
    let styleSheet = document.styleSheets[0];
    let keyframes = `@keyframes ${animationName}{
              from   { transform: translateX(0%); }
                to { transform: translateX(-${endOffset}%); }
            }`;

    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, [animationName, endOffset]);

  return (
    <div className="scroll-container bg-transparent-purple py-2.5">
      <div
        className="scroll-text text-xs font-medium tracking-[-0.48px] text-purple"
        style={{ animation: `${animationName} 3s linear infinite` }}
      >
        {text}
      </div>
    </div>
  );
};
