import React, { FC } from 'react';
import { Field } from 'formik';

import './Inputs.css';

interface CheckboxInputProps {
  id: string;
  name: string;
  label: string;
}

const CustomCheckbox = ({ field, label, id, form, ...props }: any) => {
  return (
    <>
      <input
        type="checkbox"
        id={id}
        {...field}
        {...props}
        style={{ display: 'none' }}
      />
      <label htmlFor={id} className="block">
        <div
          className={`${field.value ? 'advantages-square-checked' : 'advantages-square'}`}
        ></div>
      </label>
      <div className="text-sm">{label}</div>
    </>
  );
};

export const CheckboxInput: FC<CheckboxInputProps> = ({
  id,
  name,
  label,
}) => {
  return (
      <Field
        type="checkbox"
        className="hidden"
        id={id}
        name={name}
        label={label}
        component={CustomCheckbox}
      />
  );
};
