import React, { FC } from 'react';

import { ReactComponent as Box } from '../../static/images/box.svg';
import { ReactComponent as Dollar } from '../../static/images/dollar.svg';
import { ReactComponent as Lighting } from '../../static/images/lighting.svg';
import { ReactComponent as Pulse } from '../../static/images/pulse.svg';
import { ReactComponent as Server } from '../../static/images/server.svg';
import { ReactComponent as Wallet } from '../../static/images/wallet.svg';
import { ScrollingText } from '../ScrollingText/ScrollingText';

import {
  OpportunitiesBlock,
  OpportunitiesBlockProps,
} from './OpportunitiesBlock';

const OPPORTUNITIES: OpportunitiesBlockProps[] = [
  {
    icon: <Box height={32} width={32} />,
    title: 'High data quality',
    description:
      'No gaps, no nulls, no duplicates. To prevent reorgs and data from forks, we apply 2-waves indexing',
  },
  {
    icon: <Pulse height={32} width={32} />,
    title: 'Low-latency in real-time',
    description:
      'Our real-time endpoints are 1 block fresh for crucial metrics and 40sec fresh for others',
  },
  {
    icon: <Lighting height={32} width={32} />,
    title: 'The widest DeFi coverage',
    description:
      'We deliver new DeFi each 1-5 days. So you will be able to explore new strategies faster then competitors.',
  },
  {
    icon: <Wallet height={32} width={32} />,
    title: 'Track each deFi wallet',
    description:
      "Lambda is optimized for collecting all wallets who ever interacted with deFi. It can be done even when you don't have list of addresses and need TOP-N by TVL.",
  },
  {
    icon: <Dollar height={32} width={32} />,
    title: 'Wallets positions history from DeFi genesis',
    description:
      'Save time and costs by pre-collected  historical positions of thousands of wallets since DeFi genesis.',
  },
  {
    icon: <Server height={32} width={32} />,
    title: 'Cost efficient queries for millions of addresses',
    description:
      'Real-time APIs are hard to develop from scratch. Our engineers has 15+ years experience with bigData systems to deliver the best APIs on the market. We also optimize consuming and the way of data streaming for cost efficient consumption.',
  },
];

export const Why: FC = () => {
  return (
    <section
      className="flex flex-col items-center z-20 mb-20 mx-4 mt-24"
      data-id="block:advantages_cards--default"
    >
      <div className="mx-auto w-fit" id="benefits">
        <ScrollingText
          text="BENEFITS - BENEFITS - BENEFITS - BENEFITS - "
          endOffset={60}
        />
      </div>
      <div className="mt mx-auto mb-9 mt-[26px] w-fit text-[24px] tracking-[-2px] lg:text-[32px] xl:text-[44px] font-medium">
        WHY LAMBDA?
      </div>
      <div className="flex justify-center w-full max-w-[1180px] flex-wrap lg:gap-x-[60px] gap-x-[10px] gap-y-[40px] sm:px-5">
        {OPPORTUNITIES.map((item) => (
          <OpportunitiesBlock key={item.title} {...item} />
        ))}
      </div>
    </section>
  );
};
