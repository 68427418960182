import React, { FC } from 'react';

import { ADVANTAGES_BLOCKS } from '../../constants/common';
import { ScrollingText } from '../ScrollingText/ScrollingText';

import { GetDataBlock } from './GetDataBlock';

export const GetData: FC = () => {
  return (
    <section data-id="block:options_cards--default">
      <div className="mx-auto w-fit" id="get_data">
        <ScrollingText
          text="GET DATA - GET DATA - GET DATA - GET DATA - "
          endOffset={69}
        />
      </div>
      <div className="mx-auto mb-2 mt-[30px] w-fit text-[24px] tracking-[-2px] lg:text-[32px] xl:text-[44px] font-medium">
        GET DATA TODAY
      </div>
      <div className="xs:w-full px-[20px] lg:w-[720px] lg:mx-auto text-center text-sm mb-10">
        Lambda v.1.0 has focus on Ethereum the most popular deFi products.
        We also has chain indexers across many networks.
        Fill the form to get solution for your particular needs:
      </div>
      <div className="advantages-section">
        {ADVANTAGES_BLOCKS.map((item) => (
          <GetDataBlock key={item.title} {...item} />
        ))}
      </div>
    </section>
  );
};
