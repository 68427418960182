import React, { useEffect } from 'react';
import SnackbarProvider from 'react-simple-snackbar';

import { ConnectForm } from './components/ConnectForm/ConnectForm';
import { Footer } from './components/Footer/Footer';
import { GetData } from './components/GetData/GetData';
import { Hero } from './components/Hero/Hero';
import { Menu } from './components/Menu/Menu';
import { UseCases } from './components/UseCases/UseCases';
import { Why } from './components/WhyLambda/Why';

function App() {
  useEffect(() => {
    const id = window.location.hash.replaceAll('#', '')
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView();
    }
  }, []);

  return (
    <SnackbarProvider>
      <div className="section-container bg-black font-normal">
        <Menu />
        <div className="section">
          <Hero />
        </div>
        <div className="section long-section pt-16" id="long">
          <GetData />
          <UseCases />
          <Why />
          <div style={{ position: 'relative' }} className="overflow-hidden">
            <div className="footer-background  msx-h-full absolute inset-0 grayscale filter"></div>
            <div className="relative z-10 bg-opacity-50 text-white">
              <ConnectForm />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </SnackbarProvider>
  );
}

export default App;
