import React, { FC } from 'react';

import { ReactComponent as Logo } from '../../static/images/logo.svg';

export const Footer: FC = () => {
  const year = new Date();

  return (
    <div className="mt-10 lg:mt-16 flex flex-wrap w-full justify-between items-center gap-y-[20px] px-[30px] lg:pb-[54px] sm:pb-[10px]">
      <Logo />
      <div className="flex justify-end text-sm text-semi-white">
        <div>Lambda {year.getFullYear()}</div>
      </div>
    </div>
  );
};
