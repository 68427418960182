export const ADVANTAGES_BLOCKS = [
  {
    title: 'DeFi balances (positions)',
    description:
      'Example: How much assets was re-staked in EitherFi and locked in Pendal pools?',
    href: '#contact_us',
  },
  {
    title: 'Staking rewards, validator performance and balances',
    description:
      'Example: Who is the best Staking provider based on historical performance?',
    href: '#contact_us',
  },
  {
    title: 'ERC-20 balances',
    description:
      'Example: Get historical assets for wallet and track updates.',
    href: '#contact_us',
  }
];
