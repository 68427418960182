import React, { FC, useState } from 'react';

import { handleLinkClick } from '../../helpers/scroll';
import { ReactComponent as Logo } from '../../static/images/logo.svg';
import { Button } from '../Button';

import './Menu.css';

export const Menu: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMobileMenuClick = (id: string, isMobile = false) => {
    if (isMobile) {
      setIsOpen(false);
    }
    handleLinkClick(id, isMobile);
  };

  const handleNavigateToDocs = () => {
    window.open('https://p2p-lambda.readme.io/docs/quick-start', '_blank', 'noopener,noreferrer');
  }

  return (
    <>
      <div className={`sticky top-0 z-30`}>
        <div className="flex w-full items-center justify-between px-5 py-2 lg:px-[30px]">
          <Logo
            onClick={() => handleMobileMenuClick('hero')}
            className="cursor-pointer"
          />
          <ul className="desktop-menu hidden h-10 items-center sm:gap-3 lg:gap-10 text-sm text-white sm:flex">
            <li>
              <Button
                text="READ DOC"
                variant="primary"
                textAppearance="dark"
                onClick={handleNavigateToDocs}
              />
            </li>
            <li className="cursor-pointer font-medium sm:text-xs lg:text-sm">
              <span onClick={() => handleMobileMenuClick('get_data', false)}>
                GET DATA
              </span>
            </li>
            <li className="cursor-pointer font-medium sm:text-xs lg:text-sm">
              <span onClick={() => handleMobileMenuClick('use_cases', false)}>
                USE CASES
              </span>
            </li>
            <li className="cursor-pointer font-medium sm:text-xs lg:text-sm">
              <span onClick={() => handleMobileMenuClick('benefits', false)}>
                BENEFITS
              </span>
            </li>
            <li>
              <Button
                text="CONTACT US"
                variant="primary"
                textAppearance="dark"
                onClick={() => handleMobileMenuClick('contact_us', false)}
              />
            </li>
          </ul>
          <div className="sm:hide flex flex-row items-center gap-4 sm:hidden">
            <div>
               <Button
                text="READ DOC"
                variant="primary"
                textAppearance="dark"
                onClick={handleNavigateToDocs}
              />
            </div>
            <div
              id="menu-icon"
              className={isOpen ? 'open' : ''}
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="absolute top-[72px] w-full bg-black px-5 pb-[38px] pt-8">
            <ul className="font-medium uppercase">
              <li
                className="mb-10 cursor-pointer text-[28px] leading-[35px]"
                onClick={() => handleMobileMenuClick('contact_us', true)}
              >
                CONTACT US
              </li>
              <li
                className="mb-10 cursor-pointer text-[28px] leading-[35px]"
                onClick={() => handleMobileMenuClick('get_data', true)}
              >
                GET DATA
              </li>
              <li
                className="mb-10 cursor-pointer text-[28px] leading-[35px]"
                onClick={() => handleMobileMenuClick('use_cases', true)}
              >
                USE CASES
              </li>
              <li
                className="cursor-pointer text-[28px] leading-[35px]"
                onClick={() => handleMobileMenuClick('benefits', true)}
              >
                BENEFITS
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
