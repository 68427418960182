import React, { FC } from 'react';

import { ReactComponent as Check } from '../../static/images/icons/check.svg';

import './Hero.css';

export const Hero: FC = () => {
  return (
    <div className="hero lg:min-h-[100vh] min-h-full" id="hero">
      <div
        className="mx-auto my-0 xl:w-[1143px] md:w-[860px] max-w-full px-5 sm:pt-[265px] pt-[100px] lg:pt-[19%] text-center text-[40px] leading-[44px] md:leading-[60px] md:text-[56px] xl:text-[80px] xl:leading-[88px] font-medium uppercase tracking-[-1.8px] lg:tracking-[-2.8px]">
        REAL-TIME AND HISTORICAL P&L DATA FOR WALLETS TO TRACK DEFI PORTFOLIO
      </div>
      <div className="px-5 py-2 xs:pt-[145px] sm:pt-[230px] lg:pt-0 core-features">
        <div className="z-20 flex w-full flex-col items-center px-5">
          <ul
            className="items-center justify-center gap-x-7 gap-y-4 text-sm text-white flex flex-wrap w-[337px] lg:w-[890px] xl:w-[1000px] sm:w-[640px] md:w-[744px]">
            <li className="text-lg 3xl:text-xl font-bold leading-[23px] cursor-pointer flex gap-4 items-center justify-center">
              <Check />
              DeFi positions / balances
            </li>
            <li className="text-lg 3xl:text-xl font-bold leading-[23px] cursor-pointer flex gap-4 items-center justify-center">
              <Check />
              New protocol each 5 days
            </li>
            <li className="text-lg 3xl:text-xl font-bold leading-[23px] cursor-pointer flex gap-4 items-center justify-center">
              <Check />
              Cost efficient for Thousands of wallets
            </li>
            <li className="text-lg 3xl:text-xl font-bold leading-[23px] cursor-pointer flex gap-4 items-center justify-center">
              <Check />
              40 sec data freshness for real-time
            </li>
            <li className="text-lg 3xl:text-xl font-bold leading-[23px] cursor-pointer flex gap-4 items-center justify-center">
              <Check />
              50+ TBs wallet history data
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
