import React, { Fragment,useCallback, useState } from 'react';

import { handleLinkClick } from '../../helpers/scroll';
import { ReactComponent as ArrowRight } from '../../static/images/chevron-right.svg';
import { ReactComponent as CodeBrowser } from '../../static/images/code-browser.svg';
import { ReactComponent as Cube } from '../../static/images/cube.svg';
import { ReactComponent as Target } from '../../static/images/target.svg';
import { Button } from '../Button';
import { ScrollingText } from '../ScrollingText/ScrollingText';

import './UseCases.css';

const BLOCKS: any = [
  {
    title: 'DeFi balances for dApps',
    icon: <Cube />,
    description: (
      <>
        <div>
          Lambda provides DeFi positions for dApps. This data can answer the
          following questions for each wallet:
        </div>
        <ul className="ml-5 mt-5 list-outside list-disc">
          <li className="pb-2">
            How much ETH was supplied to the Aave pool, and which tokens were
            lent as a result?
          </li>
          <li className="pb-2">
            How many tokens a particular wallet provided to a liquidity pool?
          </li>
          <li>What is the current position of eETH in EtherFi?</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Wallets',
    icon: <Target />,
    description: (
      <>
        <div className="pb-4">
          Lambda provides real-time and historical data to wallets, including
          ERC-20 balances, prices, tokens, spam filters, transactions, events,
          and NFTs.
        </div>
        <div>
          Our main value is that we pre-index all the data, allowing us to
          deliver data feeds in the most cost-efficient way for thousands and
          millions of wallets.
        </div>
      </>
    ),
  },
  {
    title: 'Portfolio trackers',
    icon: <CodeBrowser />,
    description: (
      <>
        <div className="pb-4">
          Lambda allows you to access DeFi positions and balances not only in
          real-time (as is the case for dApps) but also to download historical
          P&L data.
        </div>
        <div>
          The detailed, in-depth history of rewards and fees is ideal for
          portfolio tracker applications that provide analytics on trading
          strategies and DeFi investments.
        </div>
      </>
    ),
  },
];

export const UseCases = () => {
  const [selectedItem, setSelectedItem] = useState(BLOCKS[0]);

  const selectedClass = useCallback(
    (item: any) => {
      return item.title === selectedItem.title ? 'bg-purple border-purple' : 'border-semi-white';
    },
    [selectedItem]
  );

  return (
    <div className="px-5 lg:px-[30px]" data-id="block:main_desc--default">
      <div className="mx-auto mt-[90px] w-fit" id="use_cases">
        <ScrollingText
          text="OVERVIEW - OVERVIEW - OVERVIEW - OVERVIEW - "
          endOffset={66}
        />
      </div>
      <div className="mx-auto mb-[32px] mt-[26px] w-full lg:w-[820px] max-w-full text-center text-[24px] font-medium tracking-[-2px] lg:text-[32px] xl:text-[44px]">
        USE-CASES
      </div>
      <section className="relative mb-10 flex w-full justify-center">
        <div className="bg-dark-grey flex flex-row flex-wrap justify-center min-w-full sm:min-w-[640px] sm:max-w-[684] lg:min-w-[880px] lg:max-w-[880px]">
          <div className="flex lg:gap-0 lg:flex-col lg:pb-0 lg:bg-dark-grey lg:w-1/2 lg:justify-start gap-y-4 flex-row flex-wrap pb-5 bg-black w-full justify-center flex">
            {BLOCKS.map((item: any) => (
              <Fragment key={item.title}>
                <div
                  className={`rainbow-feature-title items-center justify-between pl-7 pr-7 font-[700] hidden lg:flex ${selectedClass(item)}`}
                  onClick={() => setSelectedItem(item)}
                >
                  {item.title}
                  <ArrowRight />
                </div>
                <div
                  className={`px-3 mx-2 border-2 rounded-[50px] font-[700] lg:hidden ${selectedClass(item)}`}
                  onClick={() => setSelectedItem(item)}
                >
                  {item.title}
                </div>
              </Fragment>
            ))}
          </div>
          <div className="flex flex-col p-7 sm:w-full lg:w-[440px]">
            {selectedItem.icon}
            <div className="pb-5 pt-5 text-sm">{selectedItem.description}</div>
            <div>
              <Button
                text="CONNECT"
                variant="main"
                textAppearance="light"
                onClick={() => handleLinkClick('contact_us')}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
