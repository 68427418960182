import React, { FC } from 'react';

interface ButtonProps {
  text: string;
  textAppearance: 'dark' | 'light';
  onClick?: () => void;
  fullwidth?: boolean;
  variant?: 'main' | 'transparent' | 'primary';
  layout?: 'main' | 'tall' | 'small';
  href?: string;
  type?: 'submit' | 'reset' | 'button';
}

const VARIANT_CLASSES = {
  primary: 'bg-white text-black border-white box-border',
  main: 'bg-purple text-white border-purple',
  transparent: 'bg-transparent text-purple hover:bg-purple',
};

const LAYOUT_CLASSES = {
  main: 'py-2',
  tall: 'py-[18px]',
  small: 'py-3'
};

const APPEARANCE_CLASSES = {
  dark: 'text-black hover:text-black',
  light: 'text-purple hover:text-white',
};

export const Button: FC<ButtonProps> = ({
  text,
  onClick = () => {},
  variant = 'main',
  fullwidth = false,
  layout = 'main',
  textAppearance,
  href,
  ...rest
}) => {
  return (
    <button
      className={`button rounded border font-medium sm:text-xs lg:text-sm active:bg-[#6A59CE] ${VARIANT_CLASSES[variant]} ${fullwidth ? 'w-full' : 'px-4'} ${LAYOUT_CLASSES[layout]} ${APPEARANCE_CLASSES[textAppearance]}`}
      onClick={onClick}
      {...rest}
    >
      {href ? <a href={href}>{text}</a> : text}
    </button>
  );
};
