export const handleLinkClick = (id: string, isMobile = false) => {
  if (isMobile) {
    window.location.hash = id;
  }

  if (id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  window.history.pushState(
    '',
    document.title,
    window.location.pathname + window.location.search + `#${id}`
  );
};
