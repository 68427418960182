import React, { FC, ReactNode } from 'react';
export interface OpportunitiesBlockProps {
  title: string;
  icon: ReactNode;
  description: string;
}
export const OpportunitiesBlock: FC<OpportunitiesBlockProps> = ({
  title,
  icon,
  description,
}) => {
  return (
    <div className="flex flex-col items-center max-w-[160px] sm:max-w-[287px] gap-y-[14px] gap-x-[8px] text-center">
      <div>{icon}</div>
      <div className="font-bold">{title}</div>
      <div className="text-sm">{description}</div>
    </div>
  );
};
