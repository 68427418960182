import React, { FC } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { pushDataEvent } from '../../helpers/analytics';
import { Button } from '../Button';
import { CheckboxInput } from '../Inputs/Checkbox';
import { TextInput } from '../Inputs/TextInput';

interface AdvantageBlockProps {
  title: string;
  description: string;
}

export const GetDataBlock: FC<AdvantageBlockProps> = ({
  title,
  description,
}) => {
  const [openSnackbar] = useSnackbar();

  const submit = async (values: any, { resetForm }: any) => {
    const result = await fetch(
      'https://hooks.slack.com/services/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',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: JSON.stringify({
          channel: '#lambda-feed',
          username: 'lambda-feed-bot',
          text: JSON.stringify(values),
          icon_emoji: ':ghost:',
        }),
      }
    );
    if (result.ok) {
      pushDataEvent('get_offer:success', {});
      resetForm();
      openSnackbar('Thank you! Your form has been successfully submitted.');
    } else {
      pushDataEvent('get_offer:error', {});
      openSnackbar('Oops! Something went wrong, please try again later.');
    }
  };

  return (
    <div className="bg-dark-grey flex h-[431px] flex-col rounded p-[30px] text-left sm:h-[346px] lg:h-[464px]">
      <div>
        <div className="mb-3 flex font-bold">{title}</div>
        <div className="mb-4 text-xs italic opacity-80">{description}</div>
        <div className="mb-5 text-sm opacity-80">
          Choose the way to consume data:
        </div>
      </div>
      <Formik
        initialValues={{
          email: '',
          title: title,
          historicalDataset: false,
          realtimeApi: false,
          dashboard: false
        }}
        onSubmit={submit}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        })}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Form
            className="flex h-full max-w-full flex-col justify-between text-xs"
            onSubmit={handleSubmit}
          >
            <ul className="flex max-w-full list-inside list-none flex-col sm:flex-row sm:gap-x-16 lg:flex-col lg:gap-0">
              <li className="mb-4 flex items-center gap-5">
                <CheckboxInput
                  id={`${title}_historicalDataset`}
                  name={'historicalDataset'}
                  label="Historical dataset"
                />
              </li>
              <li className="mb-4 flex items-center gap-5">
                <CheckboxInput
                  id={`${title}_realtimeApi`}
                  name={'realtimeApi'}
                  label="Real-time API"
                />
              </li>
              <li className="mb-4 flex items-center gap-5">
                <CheckboxInput
                  id={`${title}_dashboard`}
                  name={'dashboard'}
                  label="Dashboard"
                />
              </li>
            </ul>
            <div>
              <Field
                name="title"
                type="hidden"
                value={values.title}
                onChange={handleChange}
              />
              <TextInput
                value={values.email}
                name="email"
                onChange={handleChange}
                label="Your Email"
                error={errors.email}
                required
              />
              <Button
                type="submit"
                text="REQUEST"
                variant="main"
                fullwidth
                textAppearance="light"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
