export const pushDataEvent = (event: string, additional: object): void => {
  // @ts-ignore
  window.dataLayer?.push?.(
    Object.assign(
      {
        event,
      },
      additional
    )
  );
};
