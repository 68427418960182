import React, { ChangeEventHandler, FC } from 'react';

interface TextInputProps {
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  label: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
}

export const TextArea: FC<TextInputProps> = ({
  name,
  value,
  onChange,
  label,
  placeholder = label,
  required = false,
  error,
}) => {
  return (
    <div className="mb-[10px]">
      <div className="mb-2 text-xs">
        {label}
        {required ? <span className="text-red">*</span> : null}
      </div>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        className={`input mb-2 w-full resize-none text-sm rounded border border-gray bg-gray px-5 py-[10px] focus:outline-0 ${error ? '!border-red' : ''}`}
        placeholder={placeholder}
        rows={3}
      />
      <div className="text-red">{error}</div>
    </div>
  );
};
